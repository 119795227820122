import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dependencies-injection"
    }}>{`Dependencies Injection`}</h1>
    <p>{`If you are familiar with `}<a parentName="p" {...{
        "href": "https://angular.io"
      }}>{`Angular`}</a>{` or `}<a parentName="p" {...{
        "href": "https://nestjs.com/"
      }}>{`NestJS`}</a>{`, you may already be familiar with `}<strong parentName="p">{`Dependencies Injection`}</strong>{`.`}</p>
    <h2 {...{
      "id": "module"
    }}>{`Module`}</h2>
    <p>{`In `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` world, every `}<strong parentName="p">{`EffectModule`}</strong>{` is `}<strong parentName="p">{`Injectable`}</strong>{`. So you can easily combine modules by `}<strong parentName="p">{`Inject`}</strong>{` one to the others.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module("A")
class ModuleA extends EffectModule<AState> {
  defaultState = {};
}

@Module("B")
class ModuleB extends EffectModule<BState> {
  defaultState = {};

  constructor(private readonly moduleA: ModuleA) {
    super();
  }
}
`}</code></pre>
    <h3 {...{
      "id": "access-appstate-from-the-other-effectmodule"
    }}>{`Access `}<inlineCode parentName="h3">{`AppState`}</inlineCode>{` from the other `}<strong parentName="h3">{`EffectModule`}</strong></h3>
    <p>{`Unlike `}<strong parentName="p">{`Redux`}</strong>{`, `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` does not have `}<strong parentName="p">{`Global Store`}</strong>{` object to store the whole state in application.
But with `}<strong parentName="p">{`Dependencies Injection`}</strong>{`, we still can access state from the other parts in our application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('A')
class ModuleA extends EffectModule<AState> {
  defaultState = {}
}

@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly moduleA: ModuleA) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      withLatestFrom(this.moduleA.state$),
      map(([payload, stateA]) => {
        ...
      })
    )
  }
}
`}</code></pre>
    <h3 {...{
      "id": "trigger-actions-of-the-other-effect-modules"
    }}>{`Trigger actions of the other effect modules`}</h3>
    <p>{`Just be similar with `}<a parentName="p" {...{
        "href": "./action#emit-actions-in-effect-using-actionscreator"
      }}>{`Emit actions in Effect using ActionsCreator`}</a>{`, we can create `}<inlineCode parentName="p">{`Action`}</inlineCode>{` using `}<strong parentName="p">{`ActionsCreator`}</strong>{` in the other `}<inlineCode parentName="p">{`EffectModule class`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module("A")
class ModuleA extends EffectModule<AState> {
  defaultState = {};

  @ImmerReducer()
  set(state: Draft<AState>, payload: string) {
    state.syncFromB = payload;
  }
}

@Module("B")
class ModuleB extends EffectModule<BState> {
  defaultState = {};

  constructor(private readonly moduleA: ModuleA) {
    super();
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      withLatestFrom(this.moduleA.state$),
      map(([payload, stateA]) => {
        return this.moduleA.getActions().set(\`\${stateA.syncFromB}\${payload}\`);
      })
    );
  }
}
`}</code></pre>
    <h2 {...{
      "id": "service"
    }}>{`Service`}</h2>
    <p>{`If you want create a pure `}<em parentName="p">{`Service`}</em>{` which separated from `}<inlineCode parentName="p">{`EffectModule class`}</inlineCode>{`, you can just decorate it by `}<inlineCode parentName="p">{`Injectable`}</inlineCode>{` decorator from `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module("Simple")
class SimpleModule extends EffectModule<SimpleState> {
  defaultState = {};

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  @Effect()
  create(payload$: Observable<CreateEntityPayload>) {
    return payload$.pipe(
      withLatestFrom(this.state$),
      exhaustMap(([payload, state]) => {
        return this.httpClient.post(\`/resources/\${state.id}\`, {
          body: payload,
        });
      })
    );
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Injectable } from "@sigi/di";
import { Observable } from "rxjs";

@Injectable()
export class HttpClient {
  constructor(private readonly tracer: Tracer) {}

  get() {}
  post<T>(config: Config = {}): Observable<T> {
    return this.send<T>({
      ...config,
      method: "POST",
    });
  }
  delete() {}
  put() {}

  private send<T>(config: Config): Observable<T> {
    return this.tracer.send(config);
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Injectable } from "@sigi/di";
import { Observable } from "rxjs";

export type TraceId = string & {
  readonly traceIdTag: unique symbol;
};

@Injectable()
export class Tracer {
  send<T>(config: Config): Observable<T> {
    const traceId = this.generateTraceId();
    this.traceStart(traceId);
    return new Observable<T>((observer) => {
      const { config, abortController } = this.convertConfig(config, traceId);
      fetch(config)
        .then((res) => {
          this.traceEnd(traceId, res);
          return res.json();
        })
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((e) => {
          observer.error(e);
        });
      return () => {
        abortController.abort();
      };
    });
  }

  private convertConfig(
    config: Config,
    traceId: TraceId
  ): { config: FetchInit; abortController: AbortController } {}

  private traceStart(traceId: TraceId) {}

  private traceEnd(traceId: TraceId, res: Response) {}

  private generateTraceId(): TraceId {}
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      